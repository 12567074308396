import { useEffect, useRef } from "react";
import { printData } from "../functions/Functions";
import Shared from "../functions/Shared";
import Drawing from "../components/Drawing";

const Contact = () => {
    const contactRef = useRef();
    const photoRef = useRef();

    useEffect(() => {
        printData(Shared.contactText, "ascii-line", photoRef);
        printData(Shared.photo, "ascii-line", contactRef);
    }, []);

    return (
        <div>
            <Drawing rotation={"Y"}>
                <div ref={photoRef} className="ascii-computer"></div>
                <div ref={contactRef} className="ascii-name"></div>
            </Drawing>
        </div>

    )
}

export default Contact;