import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Navbar } from "./components";
import { About, Contact, Home, Projects, Prompt, Skills } from "./pages";
import 'bootstrap-icons/font/bootstrap-icons.css';
import "./styles/index.css";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="skills" element={<Skills />} />
          <Route path="projects" element={<Projects />} />
          <Route path="prompt" element={<Prompt />} />
          <Route path="contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;