import { useEffect, useRef } from "react";
import { printData } from "../functions/Functions";
import Shared from "../functions/Shared";
import Drawing from "../components/Drawing";

const About = () => {
    const aboutRef = useRef();

    useEffect(() => {
        printData(Shared.aboutText, "ascii-line", aboutRef);
    }, []);

    return (
        <div>
            <Drawing rotation={"X"} sectionRef={aboutRef} />
        </div>
    )
}

export default About;