const printData = (data, classname, ref) => {
    let index = 0;

    function print() {
        if (index < data.length && ref) {
            let newElement = document.createElement("pre");
            newElement.className = classname;
            newElement.innerHTML = data[index];
            ref.current?.appendChild(newElement);

            setTimeout(print, 30);
            index++;
        }

        window.scrollTo({
            top: document.body.offsetHeight,
            behavior: "smooth"
        });
    }

    print();
}

const copyText = (text) => {
    navigator.clipboard.writeText(text);
}

export { printData, copyText };