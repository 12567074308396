const Shared = {
    cv: "/assets/documents/cv.pdf",
    email: "firatkaracax@gmail.com",
    github: "https://github.com/firatkaraca",
    linkedin: "https://tr.linkedin.com/in/firatkaraca",
    asciiName: [
        ` ▄▄▄▄▄▄▄▄▄▄▄  ▄▄▄▄▄▄▄▄▄▄▄  ▄▄▄▄▄▄▄▄▄▄▄  ▄▄▄▄▄▄▄▄▄▄▄  ▄▄▄▄▄▄▄▄▄▄▄           `,
        `▐░░░░░░░░░░░▌▐░░░░░░░░░░░▌▐░░░░░░░░░░░▌▐░░░░░░░░░░░▌▐░░░░░░░░░░░▌          `,
        `▐░█▀▀▀▀▀▀▀▀▀  ▀▀▀▀█░█▀▀▀▀ ▐░█▀▀▀▀▀▀▀█░▌▐░█▀▀▀▀▀▀▀█░▌ ▀▀▀▀█░█▀▀▀▀           `,
        `▐░▌               ▐░▌     ▐░▌       ▐░▌▐░▌       ▐░▌     ▐░▌               `,
        `▐░█▄▄▄▄▄▄▄▄▄      ▐░▌     ▐░█▄▄▄▄▄▄▄█░▌▐░█▄▄▄▄▄▄▄█░▌     ▐░▌               `,
        `▐░░░░░░░░░░░▌     ▐░▌     ▐░░░░░░░░░░░▌▐░░░░░░░░░░░▌     ▐░▌               `,
        `▐░█▀▀▀▀▀▀▀▀▀      ▐░▌     ▐░█▀▀▀▀█░█▀▀ ▐░█▀▀▀▀▀▀▀█░▌     ▐░▌               `,
        `▐░▌               ▐░▌     ▐░▌     ▐░▌  ▐░▌       ▐░▌     ▐░▌               `,
        `▐░▌           ▄▄▄▄█░█▄▄▄▄ ▐░▌      ▐░▌ ▐░▌       ▐░▌     ▐░▌               `,
        `▐░▌          ▐░░░░░░░░░░░▌▐░▌       ▐░▌▐░▌       ▐░▌     ▐░▌               `,
        ` ▀            ▀▀▀▀▀▀▀▀▀▀▀  ▀         ▀  ▀         ▀       ▀                `,
        `                                                                           `,
        ` ▄    ▄  ▄▄▄▄▄▄▄▄▄▄▄  ▄▄▄▄▄▄▄▄▄▄▄  ▄▄▄▄▄▄▄▄▄▄▄  ▄▄▄▄▄▄▄▄▄▄▄  ▄▄▄▄▄▄▄▄▄▄▄   `,
        `▐░▌  ▐░▌▐░░░░░░░░░░░▌▐░░░░░░░░░░░▌▐░░░░░░░░░░░▌▐░░░░░░░░░░░▌▐░░░░░░░░░░░▌  `,
        `▐░▌ ▐░▌ ▐░█▀▀▀▀▀▀▀█░▌▐░█▀▀▀▀▀▀▀█░▌▐░█▀▀▀▀▀▀▀█░▌▐░█▀▀▀▀▀▀▀▀▀ ▐░█▀▀▀▀▀▀▀█░▌  `,
        `▐░▌▐░▌  ▐░▌       ▐░▌▐░▌       ▐░▌▐░▌       ▐░▌▐░▌          ▐░▌       ▐░▌  `,
        `▐░▌░▌   ▐░█▄▄▄▄▄▄▄█░▌▐░█▄▄▄▄▄▄▄█░▌▐░█▄▄▄▄▄▄▄█░▌▐░▌          ▐░█▄▄▄▄▄▄▄█░▌  `,
        `▐░░▌    ▐░░░░░░░░░░░▌▐░░░░░░░░░░░▌▐░░░░░░░░░░░▌▐░▌          ▐░░░░░░░░░░░▌  `,
        `▐░▌░▌   ▐░█▀▀▀▀▀▀▀█░▌▐░█▀▀▀▀█░█▀▀ ▐░█▀▀▀▀▀▀▀█░▌▐░▌          ▐░█▀▀▀▀▀▀▀█░▌  `,
        `▐░▌▐░▌  ▐░▌       ▐░▌▐░▌     ▐░▌  ▐░▌       ▐░▌▐░▌          ▐░▌       ▐░▌  `,
        `▐░▌ ▐░▌ ▐░▌       ▐░▌▐░▌      ▐░▌ ▐░▌       ▐░▌▐░█▄▄▄▄▄▄▄▄▄ ▐░▌       ▐░▌  `,
        `▐░▌  ▐░▌▐░▌       ▐░▌▐░▌       ▐░▌▐░▌       ▐░▌▐░░░░░░░░░░░▌▐░▌       ▐░▌  `,
        ` ▀    ▀  ▀         ▀  ▀         ▀  ▀         ▀  ▀▀▀▀▀▀▀▀▀▀▀  ▀         ▀   `
    ],
    asciiComputer: [
        "         ,----------------,              ,---------,",
        "    ,-----------------------,          ,\"        ,\"|",
        "  ,\"                      ,\"|        ,\"        ,\"  |",
        " +-----------------------+  |      ,\"        ,\"    |",
        " |  .-----------------.  |  |     +---------+      |",
        " |  |                 |  |  |     | -==----'|      |",
        " |  |  SOFTWARE       |  |  |     |         |      |",
        " |  |  DEVELOPER      |  |  |/----|`---=    |      |",
        " |  |  C:\\>_          |  |  |   ,/|==== ooo |      ;",
        " |  |                 |  |  |  // |(((( [33]|    ,\"",
        " |  `-----------------'  |,\" .;'| |((((     |  ,\"",
        " +-----------------------+  ;;  | |         |,\"     ",
        "    /_)______________(_/  //'   | +---------+`,",
        "   ___________________________/___  `,`",
        "  /  oooooooooooooooo  .o.  oooo /,   \\,\"-----------",
        " / ==ooooooooooooooo==.o.  ooo= //   ,`\\--{)B     ,\"",
        "/_==__==========__==_ooo__ooo=_/'   /___________,\"",
        "``-----------------------------'"
    ],
    photo: [
        '<br>',
        "                          ░░░░░░░░░                        ",
        "                     ░░░▓▓██████████▓▓░░                    ",
        "                   ░░▒▓██████████████████▒░░                ",
        "                  ░▒███████████████████████▓░░              ",
        "                 ░▓██▓▒▒▒▒▒▒▓▓▓▓▓▓▓▓▓▓▓▓▒▓███▓░             ",
        "                ░▒██▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▓██▒░            ",
        "                ░██▓▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▓██░░           ",
        "               ░░██▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▓▓▓▒░           ",
        "                ░▓▓▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▓▓▒░           ",
        "              ░░▓▓▒▒▒▒▒▒▒▒▒░░░░░░░░░▒▒▒▒▒▒▒▒▒▒▒░            ",
        "              ░░▓▓▒▒▓▓▒▒▓▓▓▓▒▒░░▒▒▓▓▓▓▓▓▓▓▒▒▒▒░░            ",
        "               ░▒▒▒▒▒▒▒▒▓▒▒▓▓▒▒▒▒▒▓▓▒▒▒▒▒▒▒▒▒▒░             ",
        "             ░░▒░▒▒▒▒▓▓▒▓▓▒▓▓▒▒▒▒▒▒▒▓█▓▓▓▒▒▒▒▒░░░           ",
        "             ░░▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒░           ",
        "              ░▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒░           ",
        "              ░▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒░▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒░░           ",
        "              ░▒▒▒▒▒▒▒▒▒▒▒▒▒▓▓▒▒▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒░░           ",
        "              ░░▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒░░            ",
        "               ░░░▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒░░░░             ",
        "                 ░░▒▒▒▒▒▒▒▒▓▓▓▓▓▓▓▒▒▒▒▒▒▒▒░░                ",
        "                  ░▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒░                 ",
        "                  ░░▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒░░                 ",
        "                  ░░▒▓▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒░░                 ",
        "                 ░░░▒▒▓▓▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒░░                 ",
        "            ░░▒▓▓▓▒▒▒▒▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒▒▒▒▒▓▒░░░             ",
        "       ░░░░▓▓█▓▒▒▒▓▒▒▒▒▒▓▓▓▓▓▓▓▓▓▓▒▒▒▒▒▒▒▒▒▓██▓▒░░░         ",
        "     ░░▓██████▓▒▒▒▒▒▒▒▒▒▒▓▓▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▓██████▓░░░      ",
        " ░░▒▓██████████▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▓█████████▓▒░░░  ",
        "▒▓██████████████▓▒▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▓██████████████▓▒░",
        "██████████████████▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒███████████████████",
        "████████████████████▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▓█████████████████████",
        "████████████████████████▓▓▒▒▒▒▒▒▒▒▓▓████████████████████████",
        "████████████████████████████████████████████████████████████",
        "████████████████████████████████████████████████████████████",
        "████████████████████████████████████████████████████████████",
    ],
    aboutText: [
        '<br>',
        '<h1>ABOUT</h1>',
        'Hello,',
        'With a passion for software from my childhood, I am currently employed',
        'as a web application developer. Since completing my education, I\'ve been',
        'contributing to a German company specializing in modules related to',
        'Warehouse Management, Order Processing, and Invoicing for the European market.',
        'The solutions I\'ve crafted are utilized by prominent grocery chains currently',
        'operating in the European market. I play an integral part in collaborative efforts',
        'with the team. We engage in creating new modules, enhancing existing ones,',
        'and implementing improvements within the Agile Scrum framework, aligning',
        'with emerging solutions and customer demands.',
        '<br>',
        '<h1>EXPERIENCES</h1>',
        'Delta-A Software Center / Software Developer (December 2022- Present)',
        'Quorion GmbH / Software Developer (September 2021- December 2022)',
        '<br>',
        '<h1>EDUCATIONAL INFORMATION</h1>',
        '<p>License, Management Information Systems, Faculty of Economics and Administrative Sciences, ATATÜRK UNIVERSITY, 2017-2021</p>',
        '<br>'
    ],
    skillsText: [
        '<br>',
        '<h1>SKILLS</h1>',
        '<p><i class="bi bi-filetype-html"></i>HTML</p>',
        '<p><i class="bi bi-filetype-sass"></i>CSS ~ SASS ~ LESS ~ Bootstrap</p>',
        '<p><i class="bi bi-filetype-js"></i>JavaScript(ES6) ~ Canvas</p>',
        '<p><i class="bi bi-filetype-jsx"></i>React JS ~ React Native ~ (Context API ~ Redux Hooks)</p>',
        '<p><i class="bi bi-filetype-php"></i>PHP</p>',
        '<p><i class="bi bi-filetype-sql"></i>SQL Server ~ MySQL</p>',
        '<p><i class="bi bi-github"></i>Git ~ Github ~ Bitbucket</p>',
        '<p><i class="bi bi-phone-flip"></i>Responsive Design</p>',
        '<p><i class="bi bi-emoji-dizzy"></i>User Experience(UX) Design</p>',
        '<p><i class="bi bi-kanban"></i>Collaboration and Project Management</p>',
        '<p><i class="bi bi-cc-circle"></i>C and C# Programming</p>',
        '<br>',
    ],
    get contactText() {
        return [
            '<br>',
            '<h1>CONTACT</h1>',
            '<h1 class="spacing-none">--------</h1>',
            `<p class="centered-flex"><i onclick="navigator.clipboard.writeText(${this.email})" class="bi bi-copy"></i></span> ${this.email} <span></p>`,
            `<div class="navigate"><a target="_blank" href=${this.linkedin}><i class="bi bi-linkedin"></i> <span>LinkedIn</span></a</div>`,
            `<div class="navigate"><a target="_blank" href=${this.github}><i class="bi bi-github"></i> <span>Github</span></a</div>`,
            '<br>',
        ]
    },
    promptHelp: [
        '<br>',
        '<span class="text-teal">email</span>            View email',
        '<span class="text-teal">cv</span>               CV open in new tab',
        '<span class="text-teal">github</span>           Github open in new tab',
        '<span class="text-teal">linkedin</span>         Linkedin open in new tab',
        '<span class="text-teal">about</span>            Who is forrest?',
        '<span class="text-teal">skills</span>           View skills',
        '<span class="text-teal">projects</span>         View projects',
        '<span class="text-teal">contact</span>          View contact knowledge',
        '<span class="text-teal">history</span>          Get history',
        '<span class="text-teal">clear</span>            Terminal clear',
        '<br>'
    ],
    promptWelcome: [
        `  ________________  __  ________  _____    __  `,
        `  / / / __/ / /_/ / /|_/ // //  |/ / /| | / /  `,
        ` / / / /___/ _, _/ /  / // // /|  / ___ |/ /___`,
        `/_/ /_____/_/ |_/_/  /_/___/_/ |_/_/  |_/_____/`,
        '<br>',
        'Welcome to my interactive web terminal.',
        "For a list of available commands, type <span class='text-teal'>'help'</span>.",
        '<br>',
    ],
    defaultPrompt: [
        `<span class="text-not">Command not found. For a list of commands, type <span class="text-teal">'help'</span>.</span>`,
    ],
    get cvText() {
        return [
            '<br>',
            `<div class="navigate"><a target="_blank" href=${this.cv} rel="noopener noreferrer"><i class="bi bi-file-earmark-person"></i> <span>Click to open CV</span></a></div>`,
            '<br>',
        ]
    },
    get githubText() {
        return [
            '<br>',
            `<div class="navigate"><a target="_blank" href=${this.github} rel="noopener noreferrer"><i class="bi bi-github"></i> <span>Click to open Github</span></a</div>`,
            '<br>',
        ]
    },
    get linkedinText() {
        return [
            '<br>',
            `<div class="navigate"><a target="_blank" href=${this.linkedin} rel="noopener noreferrer"><i class="bi bi-linkedin"></i> <span>Click to open LinkedIn</span></a</div>`,
            '<br>',
        ]
    },
    get emailText() {
        return [
            '<br>',
            `<p class="centered-flex"><i onclick="navigator.clipboard.writeText(${this.email})" class="bi bi-copy"></i></span> ${this.email} <span></p>`,
            '<br>',
        ]
    },
    projects: [
        {
            name: "Offline Order System",
            description: "A system that utilizes local storage, session storage, and IndexedDB to generate orders without requiring an internet connection.",
            status: "ongoing"
        },
        {
            name: "Commissioning Module",
            description: "This module covers post-confirmation order processes, such as warehouse personnel access to order details, order preparation, release, and related tasks. It also enables the printing of PDF, Excel, and delivery notes.",
            status: "ongoing"
        },
        {
            name: "Return System",
            description: "This module allows returning certain products for reasons such as wrong delivery, damaged goods, etc. after the order has been shipped.",
            status: "completed"
        },
        {
            name: "Return Request Invoice",
            description: "This module allows creating invoices for return requests or generating return invoices before placing an order.",
            status: "completed"
        },
        {
            name: "Online Order System",
            description: "This module has two main components for managing orders: suppliers and customers. It provides a user-friendly website for them to easily place and manage orders, even if they use primitive technology.",
            status: "completed"
        },
        {
            name: "Warehouse Management Module",
            description: "This module manages products for sale, allowing for setting special price levels and promotional options. It also facilitates stock management and transfer transactions between branches.",
            status: "completed"
        },
        {
            name: "Invoice Module",
            description: "This module allows creating invoices from existing orders or generating invoices without placing an order. You can easily generate a PDF output of the created invoice without any additional steps.",
            status: "completed"
        },
        {
            name: "Delivery Note Module",
            description: "This module allows creating delivery notes from existing orders or generating delivery notes without placing an order. You can easily generate a PDF output of the created delivery note without any additional steps.",
            status: "completed"
        },
        {
            name: "Supplier Module",
            description: "This is a management module for suppliers and customers in business, including company system settings. You can add or update business partners, edit communication, payment and insurance information, and commercial documents.",
            status: "completed"
        },
        {
            name: "Online Template Module",
            description: "This is an online tool for creating custom designs using pre-designed templates without Photoshop or similar programs. Customers can simply drag and drop their desired product onto the template and create more brochures at a lower cost.",
            status: "completed"
        },
        {
            name: "KPI Dashboard",
            description: "A module that generates reports on products based on their expiration dates.",
            status: "completed"
        },
        {
            name: "Q-License",
            description: "The license information is checked for the use of cash registers.",
            status: "completed"
        },
        {
            name: "Open Account Tracking",
            description: "A module for tracking open accounts.",
            status: "completed"
        },
        {
            name: "Business Partner Report",
            description: "A report summarizing information about business partners, including contact details, payment status, and other related metrics.",
            status: "completed"
        },
        {
            name: "Product Report",
            description: "A report detailing the performance and availability of products in the system, including sales data and stock levels.",
            status: "completed"
        },
        {
            name: "Order Report",
            description: "A comprehensive report on orders, covering order details, status, and processing times.",
            status: "completed"
        },
        {
            name: "Revenue Report",
            description: "A financial report that tracks and summarizes the revenue generated by the business over a defined period.",
            status: "completed"
        },
        {
            name: "Main Group Report",
            description: "A report that categorizes and summarizes data by main product or service groups.",
            status: "completed"
        },
        {
            name: "Group Report",
            description: "A summary report based on product groups or business segments, useful for tracking performance by category.",
            status: "completed"
        },
        {
            name: "Product Group Report",
            description: "A more detailed report categorizing products by group, showing performance and sales data per product category.",
            status: "completed"
        },
        {
            name: "Seller Revenue Report",
            description: "A report that tracks the revenue generated by each seller, helping to analyze individual sales performance.",
            status: "completed"
        },
        {
            name: "Individual Product Group Report",
            description: "A focused report that tracks specific product group performance, highlighting sales, revenue, and stock details.",
            status: "completed"
        },
        {
            name: "Lod Report",
            description: "A report that tracks logistics or delivery operations, ensuring efficient tracking of shipments.",
            status: "completed"
        },
        {
            name: "Collection Tracking",
            description: "A report that monitors the collection status of outstanding payments and invoices, ensuring proper financial tracking.",
            status: "completed"
        },
        {
            name: "Business Partner Report",
            description: "A report summarizing information about business partners, including contact details, payment status, and other related metrics.",
            status: "completed"
        },
        {
            name: "Product Report",
            description: "A report detailing the performance and availability of products in the system, including sales data and stock levels.",
            status: "completed"
        },
        {
            name: "Order Report",
            description: "A comprehensive report on orders, covering order details, status, and processing times.",
            status: "completed"
        },
        {
            name: "Revenue Report",
            description: "A financial report that tracks and summarizes the revenue generated by the business over a defined period.",
            status: "completed"
        },
        {
            name: "Main Group Report",
            description: "A report that categorizes and summarizes data by main product or service groups.",
            status: "completed"
        },
        {
            name: "Group Report",
            description: "A summary report based on product groups or business segments, useful for tracking performance by category.",
            status: "completed"
        },
        {
            name: "Product Group Report",
            description: "A more detailed report categorizing products by group, showing performance and sales data per product category.",
            status: "completed"
        },
        {
            name: "Seller Revenue Report",
            description: "A report that tracks the revenue generated by each seller, helping to analyze individual sales performance.",
            status: "completed"
        },
        {
            name: "Individual Product Group Report",
            description: "A focused report that tracks specific product group performance, highlighting sales, revenue, and stock details.",
            status: "completed"
        },
        {
            name: "Lod Report",
            description: "A report that tracks logistics or delivery operations, ensuring efficient tracking of shipments.",
            status: "completed"
        },
        {
            name: "Collection Tracking",
            description: "A report that monitors the collection status of outstanding payments and invoices, ensuring proper financial tracking.",
            status: "completed"
        }

    ]
}

export default Shared;