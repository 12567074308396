import { useEffect, useRef } from "react";
import Shared from "../functions/Shared";
import { printData } from "../functions/Functions";
import Drawing from "../components/Drawing";

const Home = () => {
    const asciiComputerRef = useRef();
    const asciiNameRef = useRef();

    useEffect(() => {
        printData(Shared.asciiName, "ascii-line", asciiNameRef);
        printData(Shared.asciiComputer, "ascii-line", asciiComputerRef);
    }, []);

    return (
        <div>
            <Drawing rotation={"Y"}>
                <div ref={asciiComputerRef} className="ascii-computer"></div>
                <div ref={asciiNameRef} className="ascii-name" ></div>
            </Drawing>
        </div>
    );
}

export default Home;