import { NavLink } from "react-router-dom";

const Navbar = () => {
    return (
        <div className="nav">
            <div className="logo">
                <span className="active">PORTFOLIO</span>
            </div>
            <div className="nav-items">
                <NavLink className="nav-item" to={"/"}>HOME</NavLink>
                <NavLink className="nav-item" to={"about"}>ABOUT</NavLink>
                <NavLink className="nav-item" to={"skills"}>SKILLS</NavLink>
                <NavLink className="nav-item" to={"projects"}>PROJECTS</NavLink>
                <NavLink className="nav-item" to={"prompt"}>PROMPT</NavLink>
                <NavLink className="nav-item" to={"contact"}>CONTACT</NavLink>
            </div>
        </div>
    )
}

export default Navbar;