import Shared from "../functions/Shared";

const Projects = () => {
    return (
        <div className="projects">
            {
                Shared.projects.map((project) => {
                    return (
                        <div className="project">
                            {
                                project.status === "ongoing" &&
                                <div className="project-status"><i class="bi bi-star-fill"></i></div>
                            }
                            <div className="project-title">{project.name}</div>
                            <div className="project-description">{project.description}</div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Projects;