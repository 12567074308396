import { useEffect, useRef } from 'react'
import Drawing from '../components/Drawing'
import { printData } from "../functions/Functions";
import Shared from "../functions/Shared";

const Skills = () => {
    const skillsRef = useRef();

    useEffect(() => {
        printData(Shared.skillsText, "ascii-line", skillsRef);
    }, []);

    return (
        <div className='skills'>
            <Drawing rotation={"X"} sectionRef={skillsRef} />
        </div>
    )
}

export default Skills;