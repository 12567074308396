const Drawing = ({ children, sectionRef, rotation }) => {
    return (
        <div className={"drawing-container"}>
            <div ref={sectionRef} className={`drawing${rotation || ""}`}>
                {children}
            </div>
        </div>
    )
}

export default Drawing;