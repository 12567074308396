import { useState, useRef, useEffect } from "react";
import { printData } from "../functions/Functions";
import Shared from "../functions/Shared";

const Prompt = () => {
    const promptTextRef = useRef();
    const promptScreenRef = useRef();
    const promptCommentRef = useRef();

    const [promptText, setPromptText] = useState("");
    const [promptHistory, setPromptHistory] = useState(['']);

    useEffect(() => {
        promptTextRef.current.focus();
        printData(Shared.promptWelcome, "test", promptScreenRef);
    }, []);

    const promptKeyChange = (e) => {
        if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
            e.preventDefault();
        }
    }

    const promptTextChange = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            setPromptHistory((prevHistory) => [...prevHistory, promptText]);

            const element = document.createElement("div");
            element.className = "prompt-comment-area";
            element.innerText = promptCommentRef.current.textContent;
            promptScreenRef.current?.appendChild(element);

            printData(getTextByName(promptText), "", promptScreenRef);
            setPromptText("");
        }
    }

    const getTextByName = (name) => {
        name = name.trim();

        switch (name) {
            case "welcome":
                return Shared.promptWelcome;
            case "help":
                return Shared.promptHelp;
            case "about":
                return Shared.aboutText;
            case "skills":
                return Shared.skillsText;
            case "contact":
                return Shared.contactText;
            case "email":
                return Shared.emailText;
            case "projects":
                return ["<br>"].concat(Shared.projects.map(e => `<i class="bi bi-activity"></i> ${e.name}`).concat("<br>"));
            case "history":
                return ["<br>"].concat(promptHistory).concat("<br>");
            case "cv":
                window.open(Shared.cv, "_blank");
                return Shared.cvText;
            case "github":
                window.open(Shared.github, "_blank");
                return Shared.githubText;
            case "linkedin":
                window.open(Shared.linkedin, "_blank");
                return Shared.linkedinText;
            case "clear":
                setPromptHistory(['']);
                promptScreenRef.current.innerHTML = "";
                return false;
            default:
                return Shared.defaultPrompt;
        }
    }

    return (
        <div onClick={() => promptTextRef.current.focus()} className="prompt">
            <textarea
                ref={promptTextRef}
                name="prompt-text"
                className="prompt-text"
                value={promptText}
                onChange={(e) => setPromptText(e.target.value)}
                onKeyUp={promptTextChange}
                onKeyDown={promptKeyChange}>
            </textarea>

            <div ref={promptScreenRef} className="prompt-screen"></div>
            <div ref={promptCommentRef} className="prompt-comment-area">
                <span className="prompt-comment">{promptText}</span>
                <span className="prompt-typer"></span>
            </div>

        </div>
    )
}

export default Prompt;